import React from "react";
import { Link } from "react-router-dom";
import HeroAvatars from "../../components/HeroAvatars";
import variables from "../../styles/variables/base64-images/img-tags.ts";


const GrymHeroPage = () => {
  return (
    <>
      <Link
        to="/"
        className="home-button"
      >
        <img className="navigation-image" src="/assets/images/navi.png" alt="heroes_avatar" />
      </Link>
      <div className="single-hero-page">
        <div className="grym-section">
          <div className="wrapper">
            <h1 className="text--shadow">Grym</h1>
	  </div>
          <div className="hero-banner">
            <div className="hero-banner__image"></div>
          </div>
          <div className="wrapper">
            <p className="text--weight-s">
                The young and friendly offspring to the Guardian of Death, Grym has not been able to have many friends. They all seem to come and go, ferried away by his father, with little time to play with him. Annoying his father one day, he receives a mysterious puzzle to keep him occupied while his father does his duties. Failing to unlock the puzzle, he returns to his father’s keep to find him missing as the puzzle begins glowing in his hands. In a few seconds a flash of voidlike energy wraps around him, transporting him to a strange new world.  
            </p>
	  </div>
          <div className="wrapper">
            <p className="text--weight-s">
                Unaware of the journey he is about to embark on, Grym travels excitedly with the prospect of finding a true friend in this new and colorful world.    
            </p>
          </div>
          <div className="wrapper">
            <h2 className="text--shadow">Grym's Abilities</h2>
	  </div>
	  <div className="wrapper abilities">
            <ul className="text--margin-30">
	      <li className="ability-line text--margin-b-15"><img className="ability-image" src={variables.march_of_the_dead} alt="March of the Dead Icon" title="March of the Dead"/><p className="ability-description"><b>March of the Dead: </b>Poisons all enemies it hits with Attacks or Abilities for 3 seconds refreshing with each new stack, inflicting certain damage for each second. This “poison” stacks [max stacks on a single opponent is 3 stacks]</p></li>
	      <li className="ability-line text--margin-b-15"><img className="ability-image" src={variables.reapers_reach} alt="Reaper's Reach Icon" title="Reaper's Reach"/><p className="ability-description"><b>Reaper's Reach: </b>Fires his scythe in a target direction, stunning the first enemy in its path, and pulling Grym toward them, dealing small damage and applies 1 stack of poison. Also, can interact with walls and obstacles to rapidly move Grym towards the wall or obstacle reduces cooldown by half, when used this way.</p></li>
	      <li className="ability-line text--margin-b-15"><img className="ability-image" src={variables.decay} alt="Decay Icon" title="Decay"/><p className="ability-description"><b>Decay: </b>Slows any enemies affected by Aura of Death and in range by 25% for 2 sec and dealing a small amount of damage. Damage type - magical, damage amount - according to the skill level.</p></li>
              <li className="ability-line text--margin-b-15"><img className="ability-image" src={variables.oblivion_awaits} alt="Oblivion Awaits Icon" title="Oblivion Awaits"/><p className="ability-description"><b>Oblivion Awaits: </b>Grym “reaps” all the enemies on 360” around. Consumes stacks of Aura of Death, dealing a specified damage amount per stack. Once used all poison damage is removed from enemies. THIS ABILITY DOES NOT ADD A STACK OF "AURA OF DEATH"</p></li>
	    </ul>
	  </div>
        </div>
        <div className="sec-2">
          <div className="wrapper">
            <p className="text text--size-m text--weight-b text--margin-30">
              SELECT A HERO
            </p>

            <div className="sec-2__content">
              <HeroAvatars />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GrymHeroPage;

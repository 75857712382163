import React from "react";
import { Link } from "react-router-dom";
import HeroAvatars from "../../components/HeroAvatars";
import variables from "../../styles/variables/base64-images/img-tags.ts";

const TarinHeroPage = () => {
  return (
    <>
      <Link
        to="/"
        className="home-button"
      >
        <img className="navigation-image" src="/assets/images/navi.png" alt="heroes_avatar" />
      </Link>
      <div className="single-hero-page">
        <div className="tarin-section">
          <div className="wrapper">
            <h1 className="text--shadow">Tarin</h1>
	  </div>
          <div className="hero-banner">
            <div className="hero-banner__image"></div>
          </div>
          <div className="wrapper">
            <p className="text--weight-s text--margin-30">
              A former high-ranking knight, born into a family charged with maintaining peace within the ever-growing empire that was his home kingdom, Bor. At a young age, Tarin was highly educated in combat, multiple languages, knowledge of other kingdoms and their relationships with his own. As an only child, his upbringing was lonely and served only one purpose, but due to his training he understood his fate; to protect and serve his noblemen’s assets and legacy.
            </p>
	  </div>
          <div className="wrapper">
            <p className="text--weight-s">
              After a run in with an elusive agent of chaos within Bor, Tarin was left unsure about his role in society, and he renounced his familial ties and position guarding the elite members of the kingdom. He now roams the countryside a mercenary, in search of answers that led him down this path.
            </p>
          </div>
          <div className="wrapper">
            <h2 className="text--shadow">Tarin's Abilities</h2>
	  </div>
	  <div className="wrapper abilities">
            <ul className="text--margin-30">
	      <li className="ability-line text--margin-b-15"><img className="ability-image" src={variables.block_passive} alt="Block Passive Icon" title="Block Passive"/><p className="ability-description"><b>Block Passive: </b>When you attack an opponent with a basic attack this protects you for a period of time once it is activated. The first attack of an enemy is automatically defended against upon you attacking an enemy</p></li>
	      <li className="ability-line text--margin-b-15"><img className="ability-image" src={variables.fortress} alt="Fortress Icon" title="Fortress"/><p className="ability-description"><b>Fortress: </b>Grants an ally a (X) shield (Shield blocks 50% of all incoming damage)</p></li>
	      <li className="ability-line text--margin-b-15"><img className="ability-image" src={variables.defender} alt="Defender Icon" title="Defender"/><p className="ability-description"><b>Defender: </b>You dash forward and slam your shield into the opponent causing enemy damage. And stunning the first enemy hit</p></li>
              <li className="ability-line text--margin-b-15"><img className="ability-image" src={variables.weaponize} alt="Weaponize Icon" title="Weaponize"/><p className="ability-description"><b>Weaponize: </b>Tarin does a sweeping slash that hits in a arc in front of the character doing damage if the player lands a successful sword thrust 5 seconds before this ability does more damage in 360 circle and slows effected targets by 25% for 3 secs.</p></li>
	    </ul>
	  </div>
        </div>
        <div className="sec-2">
          <div className="wrapper">
            <p className="text text--size-m text--weight-b text--margin-30">
              SELECT A HERO
            </p>
            <div className="sec-2__content">
              <HeroAvatars />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TarinHeroPage;

import React from "react";

import HeroAvatars from "../../components/HeroAvatars";

const LorePage = () => {
  return (
    <>
      <div className="lore-page">
        <div className="section section-1">
          <div className="wrapper">
            <p className="text text--size-l text--weight-m text--width-m section-heading">
              LOREM IPSUM
            </p>

            <div className="section__content">
              <p className="text text--size-l text--weight-m text--margin-15">
                THE PAST
              </p>
              <p className="text--margin-15 text--shadow">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl id
                pulvinar risus in ut elit tortor odio. Vel pulvinar lacus,
                tincidunt dui id. Euismod sociis dolor ipsum at feugiat eu
                ultrices cum. Turpis viverra elit habitasse non. Lorem ipsum
                dolor sit amet, consectetur adipiscing elit. Nisl id pulvinar
                risus in ut elit tortor odio. Vel pulvinar lacus, tincidunt dui
                id. Euismod sociis dolor ipsum at feugiat eu ultrices cum.
                Turpis viverra elit habitasse non.
              </p>
              <p className="text--shadow">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl id
                pulvinar risus in ut elit tortor odio. Vel pulvinar lacus,
                tincidunt dui id. Euismod sociis dolor ipsum at feugiat eu
                ultrices cum. Turpis viverra elit habitasse non.
              </p>

              <div className="lore-concept">
                <div className="lore-concept__image"></div>
              </div>

              <p className="text--shadow">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl id
                pulvinar risus in ut elit tortor odio. Vel pulvinar lacus,
                tincidunt dui id. Euismod sociis dolor ipsum at feugiat eu
                ultrices cum. Turpis viverra elit habitasse non. Lorem ipsum
                dolor sit amet, consectetur adipiscing elit. Nisl id pulvinar
                risus in ut elit tortor odio. Vel pulvinar lacus, tincidunt dui
                id. Euismod sociis dolor ipsum at feugiat eu ultrices cum.
                Turpis viverra elit habitasse non.
              </p>
            </div>
          </div>
        </div>

        <div className="section section-2">
          <div className="wrapper">
            <div className="section__content">
              <p className="text text--size-l text--weight-b">THE PRESENT</p>
              <p className="text--margin-15 text--shadow">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl id
                pulvinar risus in ut elit tortor odio. Vel pulvinar lacus,
                tincidunt dui id. Euismod sociis dolor ipsum at feugiat eu
                ultrices cum. Turpis viverra elit habitasse non. Lorem ipsum
                dolor sit amet, consectetur adipiscing elit. Nisl id pulvinar
                risus in ut elit tortor odio. Vel pulvinar lacus, tincidunt dui
                id. Euismod sociis dolor ipsum at feugiat eu ultrices cum.
                Turpis viverra elit habitasse non.
              </p>
              <p className="text--shadow">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl id
                pulvinar risus in ut elit tortor odio. Vel pulvinar lacus,
                tincidunt dui id. Euismod sociis dolor ipsum at feugiat eu
                ultrices cum. Turpis viverra elit habitasse non.
              </p>

              <div className="lore-concept">
                <div className="lore-concept__image"></div>
              </div>

              <p className="text--shadow">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl id
                pulvinar risus in ut elit tortor odio. Vel pulvinar lacus,
                tincidunt dui id. Euismod sociis dolor ipsum at feugiat eu
                ultrices cum. Turpis viverra elit habitasse non. Lorem ipsum
                dolor sit amet, consectetur adipiscing elit. Nisl id pulvinar
                risus in ut elit tortor odio. Vel pulvinar lacus, tincidunt dui
                id. Euismod sociis dolor ipsum at feugiat eu ultrices cum.
                Turpis viverra elit habitasse non.
              </p>
            </div>
          </div>
        </div>

        <div className="section section-3">
          <div className="wrapper">
            <p className="text text--size-l text--weight-b text--width-heading text--margin-b-15">
              ARE YOU THAT HERO?
            </p>
            <HeroAvatars />
          </div>
        </div>
      </div>
    </>
  );
};
export default LorePage;

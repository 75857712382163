import React from "react";
import { Link } from "react-router-dom";
import HeroAvatars from "../../components/HeroAvatars";
import EmailForm from "../../components/EmailForm";
import variables from "../../styles/variables/base64-images/img-tags.ts";

const HomePage = () => {
  return (
    <>
      <Link
        to="/"
        className="home-button"
      >
        <img className="navigation-image" src="/assets/images/navi.png" alt="heroes_avatar" />

      </Link>
      <div className="home-page">
        <div class="launch-date">
          Available Now
        </div>
        <div className="wrapper-hero">
          <div className="logo"></div>
          <div className="hero"></div>
        </div>


          <div class="modal-container">
            <h1>
            <a href="#modal">&gt; Play Trailer</a>
            <Link
              to={{ pathname: "https://apps.apple.com/us/app/gallant-arena/id1468140034" }}
	      target="_blank"
            >
              <img className="button" src={variables.ios_icon} alt="ios" />
            </Link>
            <Link
              to={{ pathname: "https://play.google.com/store/apps/details?id=com.RadioPirates.Gallant2" }}
	      target="_blank"
            >
              <img
                className="button"
                src={variables.android_icon}
                alt="android"
              />
            </Link>
            <a href="https://discord.com/invite/24TkfhJZ77">Discord</a>
            </h1>

            <div id="modal">
              <a href="#" class="close"></a>
              <div class="youtube modal-content">
                <a href="#">x</a>
                <iframe src="https://www.youtube.com/embed/Yi0xUzWsKSA"
	          frameborder="0" allowfullscreen class="video">
                </iframe>
	      </div>
            </div>
          </div>

        <div className="sec-email">
          <div className="wrapper">
            <p className="text text--size-l text--width-heading text--weight-b text--margin-15">
              Join Us    
            </p>
            <p className="text text--width-m text--margin-15">
	      Sign up with your email to receive updates.
            </p>
            <p className="text text--width-m text--margin-15">
              <EmailForm/>
            </p>
          </div>
        </div>

        <div className="sec-2">
          <div className="wrapper">
            <p className="text text--size-l text--width-heading text--weight-b text--margin-15">
              Heroes    
            </p>
            <p className="text text--width-m text--margin-15">
              Mix & match your team from a growing roster of heroes with custom progression.
            </p>
            <div className="sec-2__content">
              <HeroAvatars isBigSize={true} />
            </div>
          </div>
        </div>

        <div className="sec-3">
          <div className="wrapper">
            <p className="text text--size-l text--width-heading text--weight-b text--margin-15">
              Features    
            </p>
            <p className="text text--width-m text--margin-15">
              Gallant is a free-to-play and master to win mobile battler. We do sell in-game items, but do not trade fun and victory. 
            </p>
            <div className="row text--margin-15">
              <div className="column">
                <ul className="text--width-m text--margin-15">
                  <li>Tactical challenge of 2v2 vs team battle</li>
                  <li>Growing roster of heroes & hero progression - each hero advances and acquires an individual set of skills. Want to add a new hero to your team? Sure, just make sure others are ready to cover for a newbie.</li>
                  <li>Ranked Competitive PVP</li>
                </ul>
              </div> 
              <div className="column">
                <ul className="text--width-m text--margin-15">
                  <li>Only worthy adversaries</li> 
		  <li>Intelligent match-making system</li>
                  <li>Regular updates with respect to community feedback</li>
                </ul>
              </div>

 
            </div>
          </div>
          <div className="wrapper">
            <p className="text text--size-m text--width-l text--weight-b">
              About Terraform Studios  
            </p>
            <p className="text text--width-l text--margin-15">
              We are a small startup development studio based in Dallas, Texas, focused on creating fantasy inspired games that push the boundaries of competitive mobile gaming. Our flagship mobile-game Gallant is currently in development. Creatively, we draw from many aspects of the gaming world and strive to create the best possible experience for our consumers and employees alike.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default HomePage;

import React from "react";
import { Link } from "react-router-dom";
import ScriptTag from 'react-script-tag';

import variables from "../../styles/variables/base64-images/img-tags.ts";

const HeroAvatars = ({ isBigSize }) => {
  return (
    <>
      <div class="roster-images">
        <div class="grym-image">
          <Link
            to="/grym-hero"
	  >
            <img id="grym-avatar" src={variables.grym_profile} alt="Grym Illustration" />
            <b id="grym-label">Grym</b>

          </Link>
        </div>
        <div class="tarin-image">
          <Link
            to="/tarin-hero"
	  >
            <img id="tarin-avatar" src={variables.tarin_profile} alt="Tarin Illustration" />
            <b id="tarin-label">Tarin</b>

          </Link>

        </div>
        <div class="ilek-image">
          <Link
            to="/"
	  >
            <img id="ilek-avatar" src={variables.ilek_profile} alt="Ilek Illustration" />
            <b id="ilek-label">Ilek</b>

          </Link>
 
        </div>
        <div class="zenshi-image">
          <Link
            to="/zenshi-hero"
	  >

            <img id="zenshi-avatar" src={variables.zenshi_profile} alt="Zenshi Illustration" />
            <b id="zenshi-label">Zenshi</b>

          </Link>

        </div>
        <div class="rayla-image">
          <Link
            to="/"
	  >

            <img id="rayla-avatar" src={variables.rayla_profile} alt="Rayla Illustration" />
            <b id="rayla-label">Rayla</b>

          </Link>

        </div>
        <div class="drglop-image">
          <Link
            to="/"
	  >

            <img id="drglop-avatar" src={variables.glop_profile} alt="Dr. Glop Illustration" />
            <b id="drglop-label">Dr. Glop</b>

          </Link>
        </div>

      </div>

      <ul className="hero-avatar">
        {isBigSize ? (
          <>
            <li className="text--margin-30">
              <Link
                to="/tarin-hero"
                className="hero-avatar__border hero-avatar__border--big"
              >
                <img src={variables.tarin_avatar} alt="tarin_avatar" />
              </Link>
              <h3>Tarin</h3>
            </li>
            <li className="text--margin-30">
              <Link
                to="/zenshi-hero"
                className="hero-avatar__border hero-avatar__border--big"
              >
                <img src={variables.zenshi_avatar} alt="zenshi_avatar" />
              </Link>
              <h3>Zenshi</h3>
            </li>
            <li className="text--margin-30">
              <Link
                to="/grym-hero"
                className="hero-avatar__border hero-avatar__border--big"
              >
                <img src={variables.grym_avatar} alt="grym_avatar" />
              </Link>
              <h3>Grym</h3>
            </li>
            <li className="text--margin-30">
              <Link
                to="/"
                className="hero-avatar__border hero-avatar__border--big"
              >
                <img src={variables.rayla_avatar} alt="rayla_avatar" />
                <img
                  className="hero-avatar__image--disabled"
                  src={variables.icon_not_available}
                  alt="icon_not_available"
                />

              </Link>
              <h3>Rayla</h3>
            </li>
            <li className="text--margin-30">
              <Link
                to="/"
                className="hero-avatar__border hero-avatar__border--big"
              >
                <img src={variables.ilek_avatar} alt="ilek_avatar" />
                <img
                  className="hero-avatar__image--disabled"
                  src={variables.icon_not_available}
                  alt="icon_not_available"
                />

              </Link>
              <h3>Ilek</h3>
            </li>
            <li className="text--margin-30">
              <Link
                to="/"
                className="hero-avatar__border hero-avatar__border--big"
              >
                <img src={variables.glop_avatar} alt="glop_avatar" />
                <img
                  className="hero-avatar__image--disabled"
                  src={variables.icon_not_available}
                  alt="icon_not_available"
                />
              </Link>
              <h3>Dr. Glop</h3>
            </li>
          </>
        ) : (
          <>
            <li>
              <Link className="hero-avatar__border" to="/tarin-hero">
                <img
                  className="hero-avatar__image"
                  src={variables.tarin_avatar}
                  alt="Tarin"
                />
              </Link>
            </li>
            <li>
              <Link className="hero-avatar__border" to="/zenshi-hero">
                <img
                  className="hero-avatar__image"
                  src={variables.zenshi_avatar}
                  alt="Zenshi"
                />
              </Link>
            </li>
            <li>
              <Link className="hero-avatar__border" to="/grym-hero">
                <img
                  className="hero-avatar__image"
                  src={variables.grym_avatar}
                  alt="Grym"
                />
              </Link>
            </li>
            <li>
              <Link className="hero-avatar__border" to="/">
                <img
                  className="hero-avatar__image"
                  src={variables.rayla_avatar}
                  alt="Rayla"
                />
                <img
                  className="hero-avatar__image--disabled"
                  src={variables.icon_not_available}
                  alt="icon_not_available"
                />
              </Link>
            </li>
            <li>
              <Link className="hero-avatar__border" to="/">
                <img
                  className="hero-avatar__image"
                  src={variables.ilek_avatar}
                  alt="Ilek"
                />
                <img
                  className="hero-avatar__image--disabled"
                  src={variables.icon_not_available}
                  alt="icon_not_available"
                />
              </Link>
            </li>
            <li>
              <Link className="hero-avatar__border" to="/">
                <img
                  className="hero-avatar__image"
                  src={variables.glop_avatar}
                  alt="Dr. Glop"
                />
                <img
                  className="hero-avatar__image--disabled"
                  src={variables.icon_not_available}
                  alt="icon_not_available"
                />
              </Link>
            </li>
          </>
        )}
      </ul>
      <ScriptTag type="text/javascript" src="https://rouninmedia.github.io/axe/axe.js" />
    </>
  );
};
export default HeroAvatars;

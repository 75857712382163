import React from "react";
import { Link } from "react-router-dom";

import variables from "../../styles/variables/base64-images/img-tags.ts";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__inner">
        <div className="left-side">
          <Link to="/">
            <img
              className="footer__logo1"
              src={variables.footer__logo1}
              alt="logo"
            />
          </Link>
          <img
            className="footer__logo2"
            src={variables.footer__logo2}
            alt="logo"
          />
        </div>

        <div className="right-side">
          <div className="right-side__buttons">
            <Link
              to={{ pathname: "https://apps.apple.com/us/app/gallant-arena/id1468140034" }}
	      target="_blank"
            >
              <img className="button" src={variables.ios_icon} alt="ios" />
            </Link>
            <Link
              to={{ pathname: "https://play.google.com/store/apps/details?id=com.RadioPirates.Gallant2" }}
	      target="_blank"
            >
              <img
                className="button"
                src={variables.android_icon}
                alt="android"
              />
            </Link>
          </div>
          <div className="right-side__texts">
            <Link
              className="text text--weight-b text--shadow-none"
              to={{ pathname: "https://www.iubenda.com/privacy-policy/42979068" }}
	      target="_blank"
            >
              Privacy
            </Link>
            <Link className="text text--weight-b text--shadow-none" 
              to={{ pathname: "https://www.iubenda.com/terms-and-conditions/42979068" }}
	      target="_blank"	    
	    >
              Terms
            </Link>
            <p className="text text--weight-b text--shadow-none">
              ©2022 Terraform studios
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";
import HeroAvatars from "../../components/HeroAvatars";

const HeroesPage = () => {
  return (
    <>
      <div className="heroes-page">
        <div className="wrapper">
          <p className="text text--size-l text--width-heading text--weight-m">
            LOREM IPSUM DOLOR TES
          </p>
          <p className="text text--width-m text--margin-b-30">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl id
            pulvinar risus in ut elit tortor odio. Vel pulvinar lacus, tincidunt
            dui id.
          </p>
          <HeroAvatars isBigSize={true} />
        </div>
      </div>
    </>
  );
};
export default HeroesPage;

import React from "react";

import RenderRoutes from "./routes/RenderRoutes";
import { Header, Footer } from "./components";

const App = () => {
  return (
    <>
      <Header />
      <main id="main-container">
        <RenderRoutes />
      </main>
      <Footer />
    </>
  );
};

export default App;

import React from "react";
import { Switch } from "react-router-dom";

import {
  HomePage,
  HeroesPage,
  TarinHeroPage,
  ZenshiHeroPage,
  GrymHeroPage,
  LorePage,
  AboutPage,
  PrivacyPage,
  TermsPage
} from "../pages";

import PublicRoute from "./PublicRoute";

const RenderRoutes = () => {
  return (
    <Switch>
      <PublicRoute exact={true} path="/">
        <HomePage />
      </PublicRoute>
      <PublicRoute exact={true} path="/heroes">
        <HeroesPage />
      </PublicRoute>
      <PublicRoute exact={true} path="/tarin-hero">
        <TarinHeroPage />
      </PublicRoute>
      <PublicRoute exact={true} path="/zenshi-hero">
        <ZenshiHeroPage />
      </PublicRoute>
      <PublicRoute exact={true} path="/grym-hero">
        <GrymHeroPage />
      </PublicRoute>
      <PublicRoute exact={true} path="/lore">
        <LorePage />
      </PublicRoute>
      <PublicRoute exact={true} path="/about-us">
        <AboutPage />
      </PublicRoute>
      <PublicRoute exact={true} path="/privacy">
        <PrivacyPage />
      </PublicRoute>
      <PublicRoute exact={true} path="/terms">
        <TermsPage />
      </PublicRoute>
    </Switch>
  );
};

export default RenderRoutes;

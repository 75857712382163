import React, { Component } from "react";
import axios from "axios";
import update from "immutability-helper";


class EmailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emails: [],
    }
  }

  loadEmails() {
    const url = "https://gallant-back.herokuapp.com" 
    axios
      .get(url + "/api/version1/emails")
      .then((response) => {
        this.setState({ emails: response.data });
      })
      .catch((error) => console.log(error));
  }

  newEmailOnEnter = (e) => {
    const url = "https://gallant-back.herokuapp.com" 
    if (e.key === "Enter" && !(e.target.value === "")) {
      axios
        .post(url + "/api/version1/emails", { email: { email: e.target.value } })
        .then((response) => {
          const emails = update(this.state.emails, {
            $splice: [[0, 0, response.data]],
          });

          this.setState({
            emails: emails,
            inputValue: "",
          });
        })
        .catch((error) => console.log(error));
    }
  };

  componentDidMount() {
    this.loadEmails();
  }

  handleChange = (e) => {
    this.setState({ inputValue: e.target.value }); 
  }

  newEmailOnclick = (e) => {
    e.preventDefault();
    const url = "https://gallant-back.herokuapp.com" 
    axios
      .post(url + "/api/version1/emails", { email: { email: this.state.inputValue } })
      .then((response) => {
        const emails = update(this.state.emails, {
          $splice: [[0, 0, response.data]],
        });

        this.setState({
          emails: emails,
          inputValue: "",
        });
      })
      .catch((error) => console.log(error));
  }

  render() {
    return (
      <div>
        <div className="email-container">
          <input
            className="new-email"
            type="text"
            placeholder="email address"
            maxLength="75"
            onKeyPress={this.newEmail}
            value={this.state.inputValue}
            onChange={this.handleChange}
          />
          <div>
            <h1 onClick={this.newEmailOnclick}><span>&gt; Sign Up</span></h1>
          </div>
        </div>
     </div>
    );
  }
}

export default EmailForm;

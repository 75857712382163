import React from "react";

const PrivacyPage = () => {
  return (
    <>
      <div className="privacy-page">
        <div className="wrapper">
          <p className="text text--size-l text--width-m text--margin-30">
            TERMS OF SERVICE
          </p>

          <div className="privacy-page__content">
            <p className="text text--width-l text--margin-15">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl id
              pulvinar risus in ut elit tortor odio. Vel pulvinar lacus,
              tincidunt dui id. Euismod sociis dolor ipsum at feugiat eu
              ultrices cum. Turpis viverra elit habitasse non. Lorem ipsum dolor
              sit amet, consectetur adipiscing elit. Nisl id pulvinar risus in
              ut elit tortor odio. Vel pulvinar lacus, tincidunt dui id. Euismod
              sociis dolor ipsum at feugiat eu ultrices cum. Turpis viverra elit
              habitasse non.
            </p>
            <p className="text text--width-l text--margin-15">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl id
              pulvinar risus in ut elit tortor odio. Vel pulvinar lacus,
              tincidunt dui id. Euismod sociis dolor ipsum at feugiat eu
              ultrices cum. Turpis viverra elit habitasse non. Lorem ipsum dolor
              sit amet, consectetur adipiscing elit. Nisl id pulvinar risus in
              ut elit tortor odio. Vel pulvinar lacus, tincidunt dui id. Euismod
              sociis dolor ipsum at feugiat eu ultrices cum. Turpis viverra elit
              habitasse non. Lorem ipsum dolor sit amet, consectetur adipiscing
              elit. Nisl id pulvinar risus in ut elit tortor odio. Vel pulvinar
              lacus, tincidunt dui id. Euismod sociis dolor ipsum
            </p>
            <p className="text text--width-l text--margin-15">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl id
              pulvinar risus in ut elit tortor odio. Vel pulvinar lacus,
              tincidunt dui id. Euismod sociis dolor ipsum at feugiat eu
              ultrices cum. Turpis viverra elit habitasse non. Lorem ipsum dolor
              sit amet, consectetur adipiscing elit. Nisl id pulvinar risus in
              ut elit tortor odio. Vel pulvinar lacus, tincidunt dui id. Euismod
              sociis dolor ipsum at feugiat eu ultrices cum. Turpis viverra elit
              habitasse non. Lorem ipsum dolor sit amet, consectetur adipiscing
              elit. Nisl id pulvinar risus in ut elit tortor odio. Vel pulvinar
              lacus, tincidunt dui id. Euismod sociis dolor ipsum at feugiat eu
              ultrices cum. Turpis viverra elit habitasse non. Lorem ipsum dolor
              sit amet, consectetur adipiscing elit. Nisl id pulvinar risus in
              ut elit tortor odio. Vel pulvinar lacus, tincidunt dui id. Euismod
              sociis dolor ipsum at feugiat eu ultrices cum. Turpis viverra elit
              habitasse non.
            </p>
            <p className="text text--width-l text--margin-15">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl id
              pulvinar risus in ut elit tortor odio. Vel pulvinar lacus,
              tincidunt dui id. Euismod sociis dolor ipsum at feugiat eu
              ultrices cum. Turpis viverra elit habitasse non. Lorem ipsum dolor
              sit amet, consectetur adipiscing elit. Nisl id pulvinar risus in
              ut elit tortor odio. Vel pulvinar lacus, tincidunt dui id. Euismod
              sociis dolor ipsum at feugiat eu ultrices cum. Turpis viverra elit
              habitasse non.
            </p>
            <p className="text text--width-l text--margin-15">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl id
              pulvinar risus in ut elit tortor odio. Vel pulvinar lacus,
              tincidunt dui id. Euismod sociis dolor ipsum at feugiat eu
              ultrices cum. Turpis viverra elit habitasse non. Lorem ipsum dolor
              sit amet, consectetur adipiscing elit. Nisl id pulvinar risus in
              ut elit tortor odio. Vel pulvinar lacus, tincidunt dui id. Euismod
              sociis dolor ipsum at feugiat eu ultrices cum. Turpis viverra elit
              habitasse non. Lorem ipsum dolor sit amet, consectetur adipiscing
              elit. Nisl id pulvinar risus in ut elit tortor odio. Vel pulvinar
              lacus, tincidunt dui id. Euismod sociis dolor ipsum
            </p>
            <p className="text text--width-l text--margin-15">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl id
              pulvinar risus in ut elit tortor odio. Vel pulvinar lacus,
              tincidunt dui id. Euismod sociis dolor ipsum at feugiat eu
              ultrices cum. Turpis viverra elit habitasse non. Lorem ipsum dolor
              sit amet, consectetur adipiscing elit. Nisl id pulvinar risus in
              ut elit tortor odio. Vel pulvinar lacus, tincidunt dui id. Euismod
              sociis dolor ipsum at feugiat eu ultrices cum. Turpis viverra elit
              habitasse non. Lorem ipsum dolor sit amet, consectetur adipiscing
              elit. Nisl id pulvinar risus in ut elit tortor odio. Vel pulvinar
              lacus, tincidunt dui id. Euismod sociis dolor ipsum at feugiat eu
              ultrices cum. Turpis viverra elit habitasse non. Lorem ipsum dolor
              sit amet, consectetur adipiscing elit. Nisl id pulvinar risus in
              ut elit tortor odio. Vel pulvinar lacus, tincidunt dui id. Euismod
              sociis dolor ipsum at feugiat eu ultrices cum. Turpis viverra elit
              habitasse non.
            </p>
            <p className="text text--width-l text--margin-15">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl id
              pulvinar risus in ut elit tortor odio. Vel pulvinar lacus,
              tincidunt dui id. Euismod sociis dolor ipsum at feugiat eu
              ultrices cum. Turpis viverra elit habitasse non. Lorem ipsum dolor
              sit amet, consectetur adipiscing elit. Nisl id pulvinar risus in
              ut elit tortor odio. Vel pulvinar lacus, tincidunt dui id. Euismod
              sociis dolor ipsum at feugiat eu ultrices cum. Turpis viverra elit
              habitasse non. Lorem ipsum dolor sit amet, consectetur adipiscing
              elit. Nisl id pulvinar risus in ut elit tortor odio. Vel pulvinar
              lacus, tincidunt dui id. Euismod sociis dolor ipsum at feugiat eu
              ultrices cum. Turpis viverra elit habitasse non. Lorem ipsum dolor
              sit amet, consectetur adipiscing elit. Nisl id pulvinar risus in
              ut elit tortor odio. Vel pulvinar lacus, tincidunt dui id. Euismod
              sociis dolor ipsum at feugiat eu ultrices cum. Turpis viverra elit
              habitasse non.psum dolor sit amet, consectetur adipiscing elit.
              Nisl id pulvinar risus in ut elit tortor odio. Vel pulvinar lacus,
              tincidunt dui id. Euismod sociis dolor ipsum at feugiat eu
              ultrices cum. Turpis viverra elit habitasse non. Lorem ipsum dolor
              sit amet, consectetur adipiscing elit. Nisl id pulvinar risus in
              ut elit tortor odio. Vel pulvinar lacus, tincidunt dui id. Euismod
              sociis dolor ipsum at feugiat eu ultrices cum. Turpis viverra elit
              habitasse non. Lorem ipsum dolor sit amet, consectetur adipiscing
              elit.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default PrivacyPage;

import React from "react";
import { Link } from "react-router-dom";
import HeroAvatars from "../../components/HeroAvatars";
import variables from "../../styles/variables/base64-images/img-tags.ts";

const ZenshiHeroPage = () => {
  return (
    <>
      <Link
        to="/"
        className="home-button"
      >
        <img className="navigation-image" src="/assets/images/navi.png" alt="heroes_avatar" />
      </Link>
      <div className="single-hero-page">
        <div className="zenshi-section">
          <div className="wrapper">
            <h1 className="text--shadow">Zenshi</h1>
	  </div>
          <div className="hero-banner">
            <div className="hero-banner__image"></div>
          </div>
          <div className="wrapper">
            <p className="text--weight-s text--margin-30">
              Born within the Royal Ceristine faction of Ceris, a prosperous and safeguarded nation atop a great tree, a young member of the nation’s Red Royal Guard soared above her peers and was devoted to upholding the values of her magnificent kingdom. While attempting to evacuate a sector of the sprawling canopy favelas to shelter during a dangerous storm, a bolt of lightning struck, hitting Zenshi full force. Instead of fatally wounding her, strangely she began to glow red, as if absorbing the energy.
            </p>
	  </div>
          <div className="wrapper">
            <p className="text--weight-s">
              Time passed and as her newfound powers began to emerge, her kingdom sought to utilize her strengths.  Zenshi became a weapon for Ceris and with her unfaltering loyalty to her nation, she welcomes all who challenge it. 
            </p>
          </div>
          <div className="wrapper">
            <h2 className="text--shadow">Zenshi's Abilities</h2>
	  </div>
	  <div className="wrapper abilities">
            <ul className="text--margin-30">
	      <li className="ability-line text--margin-b-15"><img className="ability-image" src={variables.lightning_mark} alt="Lightning Mark Icon" title="Lightning Mark"/><p className="ability-description"><b>Lightning Mark: </b>Slows enemies damaged by any of her abilities for 10% of their movement speed for 2 sec, except for her basic attack</p></li>
	      <li className="ability-line text--margin-b-15"><img className="ability-image" src={variables.lightning_arrows} alt="Lightning Arrows Icon" title="Lightning Arrows"/><p className="ability-description"><b>Lightning Arrows: </b>Fires multiples arrow within her predetermined ranged, targeting all enemies within her skill radius, if there is one enemy in range, then all four arrows go to that enemy. This is an autotarget ability. Each arrow refreshes the slow duration on the target. Applies or refreshes the duration slow on the target.</p></li>
	      <li className="ability-line text--margin-b-15"><img className="ability-image" src={variables.killer_reflexes} alt="Killer Reflexes Icon" title="Killer Reflexes"/><p className="ability-description"><b>Killer Reflexes: </b>Dodges backwards into the air, targeting a small circular area in front of her, she deals two strikes to the targeted area while landing directly away from her aimed target.</p></li>
              <li className="ability-line text--margin-b-15"><img className="ability-image" src={variables.thundering_barrage} alt="Thundering Barrage Icon" title="Thundering Barrage"/><p className="ability-description"><b>Thundering Barrage: </b>Charges for up to 2 seconds and fires a large lighting energy blast in a line in a chosen path, very long high damage skill shot. Extra damage if target is already slowed.</p></li>
	    </ul>
	  </div>
        </div>
        <div className="sec-2">
          <div className="wrapper">
            <p className="text text--size-m text--weight-b text--margin-30">
              SELECT A HERO
            </p>
            <div className="sec-2__content">
             <HeroAvatars />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ZenshiHeroPage;

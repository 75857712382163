import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
// import * as serviceWorker from "./serviceWorker";
import "./styles/normalize.css";
import "./styles/common.scss";
import ScrollToTop from "./ScrollToTop";
import App from "./App";

const history = createBrowserHistory();

const render = () => {
  ReactDOM.render(
    <Router history={history}>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </Router>,
    document.getElementById("root")
  );
};

render();

// serviceWorker.unregister();

import React from "react";

import variables from "../../styles/variables/base64-images/img-tags.ts";

const HomePage = () => {
  return (
    <>
      <div className="about-page">
        <div className="logo-section">
          <img src={variables.terraform_studios_big} alt="terraform-logo" />
          <p className="text text--size-m text--width-l">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl id
            pulvinar risus in ut elit tortor odio
          </p>
        </div>
        <div className="about-section">
          <div className="wrapper">
            <div className="about-section__content">
              <p className="text text--width-l">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl id
                pulvinar risus in ut elit tortor odio. Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. Nisl id pulvinar risus in ut
                elit tortor odio
              </p>
              <div className="blocks-container">
                <div className="block">
                  <div className="block__text half">
                    <p className="text text--size-m">Lorem ipsum</p>
                    <p className="text text--size-s text--width-m">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nisl id pulvinar risus in ut elit tortor odio. Lorem ipsum
                      dolor sit amet, consectetur adipiscing elit. Nisl id
                      pulvinar risus in ut elit tortor odio. Lorem ipsum dolor
                      sit amet, consectetur adipiscing elit. Nisl id pulvinar
                      risus in ut elit tortor odio
                    </p>
                  </div>
                  <div className="block__image half">
                    <div className="block__image__content-1"></div>
                  </div>
                </div>
                <div className="block">
                  <div className="block__image half">
                    <div className="block__image__content-2"></div>
                  </div>
                  <div className="block__text half">
                    <p className="text text--size-m">Lorem ipsum</p>
                    <p className="text text--size-s text--width-m">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nisl id pulvinar risus in ut elit tortor odio. Lorem ipsum
                      dolor sit amet, consectetur adipiscing elit. Nisl id
                      pulvinar risus in ut elit tortor odio. Lorem ipsum dolor
                      sit amet, consectetur adipiscing elit. Nisl id pulvinar
                      risus in ut elit tortor odio
                    </p>
                  </div>
                </div>
              </div>
              <ul className="email">
                <li className="email__half">
                  <p>EMAIL US</p>
                  <p className="text text--size-s">info@terraform.com</p>
                </li>
                <li className="email__half">
                  <p>JOIN US</p>
                  <p>job@terraform.com</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HomePage;
